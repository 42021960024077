import { ProfessionalArea } from "@/Models/ProfessionalArea";
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from "../Internal/AjaxService";

const DocwareOutboundController = {
    getProfessionalAreas: async () => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `/docwareOutbound/professiona-areas`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (response.error) throw new Error(response.error);
        return response.payload as ProfessionalArea[];
    },
};

export default DocwareOutboundController;
